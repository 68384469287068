import './App.css';
import Profile from './Profile';
import Experience from './Experience';



function App() {
  return (
    <div className="App">
      <Profile />
      <hr />
      {/* <CurrentExperience /> */}
      <Experience />
      

    </div>
  );
}

export default App;
